
import img11 from "../assets/T11.jpeg";
import img12 from "../assets/T12.jpeg";
import img13 from "../assets/T13.jpg";
import img14 from "../assets/T14.jpg";


const data = [

  {
    id: 11,
    title: "SASTRY BHAVAN",
    address: "STREET NO 3 LEFT SIDE HMT NAGAR NACHARAM HYDERABAD",
    img: img11,
    sqft: "11530Sft",
    noOfFlat: "10",
    flatSize: "1153 sft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps?q=17.423433303833008,78.54863739013672&z=17&hl=en",
    description: "",
  },
  {
    id: 12,
    title: "SRINIVASA NILAYAM",
    address: "STREET NO 2 HMT NAGAR NACHARAM HYDERABAD",
    img: img12,
    sqft: "11500Sft",
    noOfFlat: "10",
    flatSize: "1150 sft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps?q=IksGuest+house,+4-9-21,+Street+No.+2,+Bapuji+Nagar,+MBD+Complex,+Bapu+Nagar,+Nacharam,+Hyderabad,+Telangana+500007&ftid=0x3bcb99af8d675c53:0x925221809e394666&hl=en-IN&gl=in&entry=gps&g_ep=CAISBjYuNDguMRgAINeCA0ICSU4%3D&g_st=iw",
    description: "",
  },
  {
    id: 13,
    title: "Sri Gnanavidya Nilayam",
    address: "STREET NO 7 BAPU NAGAR HABSIGUDA HYDERABAD",
    img: img13,
    sqft: "7500Sft",
    noOfFlat: "4",
    flatSize: "1875 sft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps?q=IksGuest+house,+4-9-21,+Street+No.+2,+Bapuji+Nagar,+MBD+Complex,+Bapu+Nagar,+Nacharam,+Hyderabad,+Telangana+500007&ftid=0x3bcb99af8d675c53:0x925221809e394666&hl=en-IN&gl=in&entry=gps&g_ep=CAISBjYuNDguMRgAINeCA0ICSU4%3D&g_st=iw",
    description: "",
  },
  {
    id: 14,
    title: "Brundavanam",
    address: "HMT NAGAR NACHARAM HYDERABAD",
    img: img14,
    sqft: "",
    noOfFlat: "10",
    flatSize: "3BHK",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps/place/106,+Street+Number+4,+MBD+Complex,+HMT+Nagar,+Nacharam,+Hyderabad,+Telangana+500007/@17.4231723,78.5492121,19.14z/data=!4m16!1m10!4m9!1m3!2m2!1d78.5491537!2d17.4233763!1m3!2m2!1d78.54907!2d17.423371!3e0!3m4!1s0x3bcb995c7f58f891:0x3ce5299ecfc0061b!8m2!3d17.4231948!4d78.5490852",
    description: "",
  },
];
export default data;
