import img1 from "../assets/sulochana arcade (2).png";
import img2 from "../assets/2.jpeg";
import img3 from "../assets/3.jpeg";
import img4 from "../assets/4.jpeg";
import img5 from "../assets/5.jpeg";
import img6 from "../assets/6.jpeg";
import img7 from "../assets/7.jpeg";
import img8 from "../assets/8.jpeg";
import img9 from "../assets/9.jpeg";
import img10 from "../assets/10.jpeg";
import img11 from "../assets/T1.png";
import img12 from "../assets/T3.jpg";
import img13 from "../assets/T2.jpg";
import img14 from "../assets/T4.jpg";
import img15 from "../assets/T5.jpg";
import img16 from "../assets/T8.jpeg";
import img17 from "../assets/T6.jpg";
import img18 from "../assets/T7.jpeg";
import img19 from "../assets/T9.jpeg";
import img20 from "../assets/T10.jpeg";

const data = [
  {
    id: 1,
    title: "SULOCHANA ARCADE",
    address: "STREET NO1  HMT Nagar, Nacharam, Hyderabad",
    img: img1,
    totalSqFt: "400 Sq.Yds",
    flats: "9",
    municipality: "GHMC",
    completionStatus: "2014",
    status: "Sold",
  },
  {
    id: 2,
    title: "SRI SURYA RESIDANCY",
    address: "Eshwarpuri Colony, Sainikpuri",
    img: img2,
    totalSqFt: "700 Sq.Yds",
    flats: "10",
    municipality: "GHMC",
    completionStatus: "2014",
    status: "Sold",
  },
  {
    id: 3,
    title: "Sri Nilayam",
    address: "Manikonda, Hyderabad",
    img: img3,
    totalSqFt: "600 Sq.Yds",
    flats: "10",
    municipality: "GHMC",
    completionStatus: "2017",
    status: "Sold",
  },
  {
    id: 4,
    title: "Sri Raghavendra RESIDANCY",
    address: "Manikonda Hyderabad",
    img: img4,
    totalSqFt: "533 Sq.Yds",
    flats: "15",
    municipality: "GHMC",
    completionStatus: "2016",
    status: "Sold",
  },

  {
    id: 5,
    title: "Acropolis Tulip",
    address: "Moulali, Hyderabad",
    img: img5,
    totalSqFt: "800 Sq.Yds",
    flats: "12",
    municipality: "GHMC",
    completionStatus: "2016",
    status: "Sold",
  },
  {
    id: 6,
    title: "Richmon Enclave",
    address: "Kondapur, Hyderaabad",
    img: img6,
    totalSqFt: "800 Sq.Yds",
    flats: "15",
    municipality: "GHMC",
    completionStatus: "2015",
    status: "Sold",
  },
  {
    id: 7,
    title: "Sampath Soudha",
    address: "Manikonda, Hyderabad",
    img: img7,
    totalSqFt: "480 Sq.Yds",
    flats: "10",
    municipality: "GHMC",
    completionStatus: "2014",
    status: "Sold",
  },
  {
    id: 8,
    title: "Sai Krishna Residency",
    address: "Nagole, Hyderabad",
    img: img8,
    totalSqFt: "700 Sq.Yds",
    flats: "5",
    municipality: "GHMC",
    completionStatus: "2018",
    status: "Sold",
  },
  {
    id: 9,
    title: "Divya Leela Residency",
    address: "Karthikeya Nagar, Nacharam, Hyderabad",
    img: img9,
    totalSqFt: "700 Sq.Yds",
    flats: "4",
    municipality: "GHMC",
    completionStatus: "2010",
    status: "In process",
  },
  {
    id: 10,
    title: "Soundarya Residency",
    address: "ASRao Nagar, ECIL, Secunderabad",
    img: img10,
    totalSqFt: "800 Sq.Yds",
    flats: "4",
    municipality: "GHMC",
    completionStatus: "2010",
    status: "In process",
  },
  {
    id: 11,
    title: "SREE MAYUKHA",
    address: "Bapuji Nagar, HMT Nagar Hyderabad",
    img: img11,
    sqft: "11,120 sq.ft",
    noOfFlat: "8",
    flatSize: "1390 sft",
    municipality: "GHMC",
    status: "Completed",
    flats: "SOLD OUT",
    gmap: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15227.228784125653!2d78.549709!3d17.421038!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb995b5e41806d%3A0xbd7e50ed45e6e56f!2sHMT%20Nagar%2C%20Nacharam%2C%20Secunderabad%2C%20Telangana%20500007!5e0!3m2!1sen!2sin!4v1626191636165!5m2!1sen!2sin",
    description: "",
  },
  {
    id: 12,
    title: "SREE NELIYAM",
    address: "STREET NO-1BAPUJI NAGAR HMT NACHARAM HYDERABAD",
    img: img12,
    sqft: "6280Sft",
    noOfFlat: "4",
    flatSize: "1570 Sqft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.7744360054667!2d78.54425911487684!3d17.42260928805752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTfCsDI1JzIxLjQiTiA3OMKwMzInNDcuMiJF!5e0!3m2!1sen!2sin!4v1631970482031!5m2!1sen!2sin",

    description: "",
  },
  {
    id: 13,
    title: "SWAPNA RESIDENCY",
    address: "Karthikeya Nagar, Nacharam, Hyderabad",
    img: img13,
    sqft: "7800 Sft",
    noOfFlat: "4",
    flatSize: "1950 Sft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d237.91513072749498!2d78.5473284!3d17.4289556!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTfCsDI1JzQ4LjUiTiA3OMKwMzInNDcuMSJF!5e0!3m2!1sen!2sin!4v1631969775065!5m2!1sen!2sin",
    description: "",
  },
  {
    id: 14,
    title: "ACROPOLIS TULIP",
    address: "	Boddupal, Uppal, Hyderabad",
    img: img14,
    sqft: "20,500 sq.ft",
    noOfFlat: "20",
    flatSize: "1025 Sft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.001943298651!2d78.5718391148767!3d17.411694388064028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTfCsDI0JzQyLjEiTiA3OMKwMzQnMjYuNSJF!5e0!3m2!1sen!2sin!4v1631969873643!5m2!1sen!2sin",
    description: "",
  },
  {
    id: 15,
    title: "PRAMILA RESIDENCY",
    address: "SREET NO 10 KARTHIKEYA NAGAR ",
    img: img15,
    sqft: "7100.00Sft",
    noOfFlat: "4",
    flatSize: "1775 sqft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.617276991541!2d78.54421331487694!3d17.43014528805299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTfCsDI1JzQ4LjUiTiA3OMKwMzInNDcuMSJF!5e0!3m2!1sen!2sin!4v1631969950700!5m2!1sen!2sin",
    description: "",
  },
  {
    id: 16,
    title: "RAGHAVENDRA NILAYAM",
    address: "STREET NO-1 BAPUJI NAGAR HMT NACHARAM HYDERABAD",
    img: img16,
    sqft: "6100SSft",
    noOfFlat: "4",
    flatSize: "1525 sft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "SOLD OUT",
    gmap: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.617410487857!2d78.54422801487698!3d17.43013888805305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTfCsDI1JzQ4LjUiTiA3OMKwMzInNDcuMSJF!5e0!3m2!1sen!2sin!4v1631970314345!5m2!1sen!2sin",
    description: "",
  },
  {
    id: 17,
    title: "NEEHAARIKA HOMES",
    address: "SREET NO 10 KARTHIKEYA NAGAR  ",
    img: img17,
    sqft: "8040Sft",
    noOfFlat: "4",
    flatSize: "2010 sft",
    costOfProject: "",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.617276991541!2d78.54421331487694!3d17.43014528805299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTfCsDI1JzQ4LjUiTiA3OMKwMzInNDcuMSJF!5e0!3m2!1sen!2sin!4v1631970185200!5m2!1sen!2sin",
    description: "",
  },
  {
    id: 18,
    title: "PRASANTH KUTTER",
    address: "SREET NO 9 KARTHIKEYA NAGAR",
    img: img18,
    sqft: "13500Sft",
    noOfFlat: "10",
    flatSize: "1450 sft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.617410487857!2d78.54422801487698!3d17.43013888805305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTfCsDI1JzQ4LjUiTiA3OMKwMzInNDcuMSJF!5e0!3m2!1sen!2sin!4v1631970314345!5m2!1sen!2sin",
    description: "",
  },
  {
    id: 19,
    title: "SREE KRISHNA",
    address: "BAPUJI NAGAR HMT NACHARAM HYDERABAD",
    img: img19,
    sqft: "13500Sft",
    noOfFlat: "10",
    flatSize: "1390 sft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3806.785429834308!2d78.5476243!3d17.422082!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7019410cec71e9!2zMTfCsDI1JzIyLjYiTiA3OMKwMzInNTIuOCJF!5e0!3m2!1sen!2sin!4v1644335706587!5m2!1sen!2sin",
    description: "",
  },
  {
    id: 20,
    title: "SIVASRI NELAYAM",
    address: "STREET NO 3 HMT NACHARAM HYDERABAD",
    img: img20,
    sqft: "11250Sft",
    noOfFlat: "10",
    flatSize: "1125 sft",
    municipality: "GHMC",
    status: "Ongoing",
    flats: "Available",
    gmap: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3806.785429834308!2d78.5476243!3d17.422082!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7019410cec71e9!2zMTfCsDI1JzIyLjYiTiA3OMKwMzInNTIuOCJF!5e0!3m2!1sen!2sin!4v1637853797593!5m2!1sen!2sin",
    description: "",
  },
];
export default data;
