import img1 from "../assets/AP4.jpg";
import img2 from "../assets/AP5.jpg";

const data = [
  {
    id: 1,
    title: "Swarna Residency",
    address: "Sandipani nagar, Geetam University road, Yendada, 53004",
    img: img1,
    sqft: "",
    noOfFlat: "",
    flatSize: "",
    municipality: "",
    status: "In Progress",
    flats: "",
    gmap: "https://maps.google.com/maps?q=17.78287696838379%2C83.3594741821289&z=17&hl=en",
    description: "",
  },
  {
    id: 2,
    title: "Amrutha Residency",
    address: "Opposite 14-238, Construction Site., Opposite Siri Sampadana Nilayam, Balaji Gardens, Prahaladapuram, Simhachalam, Visakhapatnam, Andhra Pradesh, India",
    img: img2,
    sqft: "",
    noOfFlat: "",
    flatSize: "",
    municipality: "",
    status: "In Progress",
    flats: "",
    gmap: "https://maps.google.com/maps?q=17.75615692138672%2C83.21871948242188&z=17&hl=en",
    description: "",
  }

];
export default data;
